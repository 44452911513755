
import { ProfilePage } from '../../modals/profile/profile.page';
import { NgZone, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { InvitePage } from '../../modals/invite/invite.page'
import { GroupPage } from '../../modals/group/group.page'
import { FormBuilder, FormGroup } from '@angular/forms';
import axios from 'axios'
import socket from "../../../socket"
import {Howl, Howler} from 'howler';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../event-service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import Auth from '../../../service/auth.js'
import util from '../../../util.js'
import config from '../../../config.js'
// import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// const PostHelper = require('../../helper/post-helper')
import {ApiRequestService} from '../../../service/requests.service'
import { chat, groups } from './data';
import { Chats, Groups } from './chat.model';
import { Lightbox } from 'ngx-lightbox';
import { DataService } from "../../app.service";
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
declare const window: any;

// Date Format
import {DatePipe} from '@angular/common';

// const bodyClassList = document.body.classList
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

/**
 * Chat-component
 */
export class IndexComponent implements OnInit {
  selectedUser: any = {}

  activetab = 2;
  chat: Chats[];
  groups: Groups[];
  subscribleMessage: any = null
  session: any = null
  formData!: FormGroup;
  @ViewChild('scrollRef') scrollRef:any;
  @ViewChild('videoCall') videoCall:any;
  @ViewChild('room') room:any;
  emoji = '';
  videoSelected: any = null
  isreplyMessage = false;
  textSearch = ""
  callSound:any = ""
  isgroupMessage = false;
  mode: string | undefined;
  public isCollapsed = true;
  muteMicro: Boolean = false
  muteVolume: Boolean = false

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Portuguese', flag: 'assets/images/flags/portugal.png', lang: 'pt' },
    { text: 'French', flag: 'assets/images/flags/french.jpg', lang: 'fr' },
  ];
  userGroups = [
    'demo', 'Admin', 'Operator', 'Doctor'
  ]
  lang: string;
  images: { src: string; thumb: string; caption: string }[] = [];
  socketId: any = ""
  isInitSocket: Boolean = false
  optionCall: any = {}
  remoteVideos: any = []

  audio = new Audio('assets/sound/call.mp3');
  messageSound = new Audio('assets/sound/message.mp3');
  sendMessageSound = new Audio('assets/sound/send.mp3');
  constructor(
    public req: ApiRequestService,
    private _ngZone: NgZone,
    public events: EventsService, public formBuilder: FormBuilder, 
    public dataService: DataService,
    private authFackservice: AuthfakeauthenticationService, private authService: AuthenticationService,
    private router: Router, public translate: TranslateService, private modalService: NgbModal, private offcanvasService: NgbOffcanvas,
    private datePipe: DatePipe,private lightbox: Lightbox) {
 


      socket.init((socketId:any) => {
        console.log(socketId)
        this.socketId = socketId
        this.pageLoaded()
        console.log("Socket")
      })


    this.events.getObservable().subscribe((data) => {
      if (data.type === 'login') {
        this.pageLoaded()
      } else if (data.type === 'call') {
        this._handleCall(data.data)
        this._sendACall(data.data)
      }
    })
    }


    _sendACall(optionCall:any) {
      optionCall.text = `${optionCall.group.member.fullName} open a call`
      axios.post(`${config.api.sendACall}${optionCall.group.id}`, {
        optionCall: optionCall
      })
    }
  
   /**
   * Open lightbox
   */
   openImage(index: number,i:number): void {
    // open lightbox
    this.lightbox.open(this.message[index].imageContent, i, {
      showZoom: true
    });

  }
  senderName:any;
  senderProfile:any;
  async ngOnInit() {

    this.formData = this.formBuilder.group({
      message: [''],
    });
  }

  async pageLoaded() {

    const token = this.req.getToken()
    this.req.setTokenToHeader()
    // console.log()
    try {
      const data = await this.getProfile()
      if (!data) return
      this.dataService.currentUser = data
      window.localStorage.setItem("user_id", data.id);
      
      this._handleSocket()

    } catch (error) {
      if (error == 'Error: Request failed with status code 401') {
        this.req.removeToken()
        this.router.navigate(['/login'])
      }
      return
    }


    document.body.setAttribute('data-layout-mode', 'light');

    // Validation
    // this.formData = this.formBuilder.group({
    //   message: ['', [Validators.required]],
    // });

    const user = this.dataService.currentUser;
    // console.log(user)
    this.senderName = user.fullName
    this.senderProfile = 'assets/images/users/'+user.profile
    this.chat = chat;
    this.groups = groups;
    this.lang = this.translate.currentLang;
    this.onListScroll();

    await this.getGroups()
  }
  async getProfile () {
    return await this.req.getProfile(this.socketId)
  }

  ngAfterViewInit() {
    this.scrollRef.SimpleBar.getScrollElement().scrollTop = 100;
  }

  /**
   * Show user profile
   */
  // tslint:disable-next-line: typedef
  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
  }

  /**
   * Logout the user
   */
  logout() {
    Auth.removeToken()
    this.router.navigate(['/account/login'])
  }

  /**
   * Set language
   * @param lang language
   */
  setLanguage(lang) {
    this.translate.use(lang);
    this.lang = lang;
  }

  openCallModal(content) {

    this.modalService.open(content, { centered: true });
  }

  openVideoModal(videoContent) {
    this.modalService.open(videoContent, { centered: true, fullscreen: true });
  }
  openInvite(call = false) {
    const inviteModal = this.modalService.open(InvitePage, { centered: true });
    inviteModal.componentInstance.group = this.dataService.group;
    inviteModal.componentInstance.call = call ? this.optionCall : false;
    
  }
  /**
   * Show user chat
   */
  // tslint:disable-next-line: typedef
  members:any = []
  userName:any = 'Doris Brown';
  userStatus:any = 'online';
  userProfile:any = 'assets/images/users/avatar-4.jpg';
  message:any = [];
  chatTab = 0;
  async showChat(event:any,chat:any) {
    if (chat.is_request && chat.operator_id == 0) {
      await this.joinGroup(chat.id);
      this.getGroups()
    }
    if (this.subscribleMessage) {
      this.dataService.group = null;
      this.message = null;
      this.subscribleMessage.unsubscribe()
    }
    
    this.dataService.group = chat
    // console.log(chat)
    var removeClass = document.querySelectorAll('.chat-user-list li');
    removeClass.forEach((element: any) => {
        element.classList.remove('active');
    });
    
    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    event.target.closest('li').classList.add('active');
    // var data = this.chat.filter((chat:any) => {
    //   return chat.id === chat.id;
    // });
    this.userName = chat.name ? chat.name : "No name" 
    this.userStatus = 0
    this.userProfile = chat.avatarFullUrl


    const res = await axios.get(`${config.api.detailGroup}${this.dataService.group.id}`)
    this.dataService.group = res.data.group
    this.members = res.data.members
    this.dataService.group.member = this._getMember(res.data.group)
    let messages = await this._getListMessage();

    this.message =  this._formatMessage(messages.result)
    this.subscribleMessage = this.events.getObservable().subscribe((data) => {
      console.log("newmess")
      if (data.data.group !== this.dataService.group.id) return
      if (data.type === 'message') {
        const message = data.data
        message.createdAt = util.dateTime(message.createdAt)
        message.isYourMessage = true
        const index = this.members.findIndex((mem:any) => {
          return mem.id === message.createdBy
        })
        if (index !== -1) {
          message.from = this.members[index]
        }

        if (!this.message.length) {
          message.showUserData = true
        } else {
          if (message.createdBy !== this.message[this.message.length - 1].createdBy) {
            message.showUserData = true
          }
        }
        this.message.push(message)
      } else if (data.type === 'delete-message') {
        const index = this.message.findIndex((item: any) => {
          return item.id === data.data
        })
        if (index != -1) {
          this.message.splice(index, 1)
        }
      }
      this.readMessage()
    this.onListScroll();
    })
    // console.log(this.message)
      this.readMessage()
    this.onListScroll();
  }

  async joinGroup(id) {
    const res = await axios.post(config.api.joinGroup, {id:id})
    return res;
  }
  _getMember(group:any) {
    if (group.group) {
      return {
        id: 1,
        fullName: group.name,
        bgColor: group.color,
        avatarFullUrl: group.avatarFullUrl,
      }
    } else {
      // ТУТ!
      const index = this.members.findIndex((item:any) => {
        return item.id !== this.dataService.currentUser.id
      })
      if (index !== -1) {
        return this.members[index]
      }
    }
  }

  _formatMessage(messages: any) {
    const mes = messages.map((item:any, key:any) => {
      // TUT!
      const index = this.members.findIndex((mem: any) => {
        return mem.id === item.createdBy
      })
      if (index !== -1) {
        item.from = this.members[index]
      }
      item.createdAt = util.dateTime(item.createdAt)
      if (item.createdBy !== this.dataService.currentUser.id) {
        item.isYourMessage = true
      }
      if (!key) {
        item.showUserData = true
      } else {
        if (item.createdBy !== messages[key - 1].createdBy) {
          item.showUserData = true
        }
      }

      return item
    })
    return mes
  }

  async _getListMessage() {
    const res = await axios.get(`${config.api.listMessage}${this.dataService.group.id}`)
    return res.data
  }
  // Contact Search
  ContactSearch(){
    var input:any, filter:any, ul:any, li:any, a:any | undefined, i:any, txtValue:any;
    input = document.getElementById("searchContact") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".chat-user-list");
    ul.forEach((item:any)=>{
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
      }
    })
  }

  // Message Search
  MessageSearch(){
    var input:any, filter:any, ul:any, li:any, a:any | undefined, i:any, txtValue:any;
    input = document.getElementById("searchMessage") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.getElementById("users-conversation");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("p")[0];
      txtValue = a?.innerText;
      if (txtValue?.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
    } else {
        li[i].style.display = "none";
    }
    }
  }

  // Filter Offcanvas Set
  onChatInfoClicked(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  /**
   * Returns form
   */

  /**
   * Save the message in chat
   */
  statOn() {

    const payload = {
      text: "/stethOn"
    }
    this._sendMessage(payload)
  }

  statOff() {

    const payload = {
      text: "/stethOff"
    }
    this._sendMessage(payload)
  }
   messageSave() {
    // alert(this.emoji)
    const message = this.formData.get('message')!.value;
    
    if (!message) return
    const payload = {
      text: message
    }
    this._sendMessage(payload)


    this.onListScroll();

    // Set Form Data Reset
    // this.formData = this.formBuilder.group({
    //   message: null,
    // });
    this.isreplyMessage = false;
    this.emoji = '';
    this.img = '';
    document.querySelector('.replyCard')?.classList.remove('show');
   }


  async _sendMessage(payload:any) {
    payload.group = this.dataService.group.id
    const message = {...payload}
    message.createdBy = this.dataService.currentUser.id
    message.from = this.dataService.currentUser
    // alert(new Date())
    message.createdAt = util.dateTime()
    message.updateAt = util.dateTime()
    if (!this.message.length) {
      message.showUserData = true
    } else {
      if (message.createdBy !== this.message[this.message.length - 1].createdBy) {
        message.showUserData = true
      }
    }
    if (payload.image) {
      message.image = payload.imageFullUrl
      delete payload.imageFullUrl
    }

    if (payload.video) {
      message.video = payload.videoFullUrl
      delete payload.videoFullUrl
    }
    this.message.push(message)
    const indexMessage = this.message.length - 1
    // this.text = ''
    const res = await axios.post(config.api.createMessage, payload)
    this.message[indexMessage].id = res.data.id
    this.onListScroll()
  }


   onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement( ).scrollHeight;
      }, 10);
    }
  }

   // Emoji Picker
   showEmojiPicker = false;
   sets:any = [
     'native',
     'google',
     'twitter',
     'facebook',
     'emojione',
     'apple',
     'messenger'
   ]
   set:any = 'twitter';
   toggleEmojiPicker() {
     this.showEmojiPicker = !this.showEmojiPicker;
   }

   addEmoji(event:any) {
     const { emoji } = this;
     const text = `${emoji}${event.emoji.native}`;
     this.emoji = text;
     this.showEmojiPicker = false;
   }

   onFocus() {
     this.showEmojiPicker = false;
   }
   onBlur() {
   }

   closeReplay(){
    document.querySelector('.replyCard')?.classList.remove('show');
  }

   // Copy Message
   copyMessage(event:any){
    navigator.clipboard.writeText(event.target.closest('.chats').querySelector('.messageText').innerHTML);
    document.getElementById('copyClipBoard')?.classList.add('show');
    setTimeout(() => {
      document.getElementById('copyClipBoard')?.classList.remove('show');
    }, 1000);
  }

  // Delete Message
  deleteMessage(event:any){
    event.target.closest('.chats').remove();
  }

  // Delete All Message
  deleteAllMessage(event:any){
    var allMsgDelete:any = document.getElementById('users-conversation')?.querySelectorAll('.chats');
    allMsgDelete.forEach((item:any)=>{
      item.remove();
    })
  }

  // Replay Message
  replyMessage(event:any,align:any){
    this.isreplyMessage = true;
    document.querySelector('.replyCard')?.classList.add('show');
    var copyText = event.target.closest('.chats').querySelector('.messageText').innerHTML;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerHTML = copyText;
    var msgOwnerName:any = event.target.closest(".chats").classList.contains("right") == true ? 'You' : document.querySelector('.username')?.innerHTML;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML = msgOwnerName;
  }

   /**
   * Open center modal
   * @param centerDataModal center modal data
   */
    centerModal(centerDataModal: any) {
      this.modalService.open(centerDataModal, { centered: true });
    }

  // File Upload
  imageURL: string | undefined;
  img:any;
  fileChange(event:any) {
    let fileList: any = (event.target as HTMLInputElement);
    let file: File = fileList.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      this.img = this.imageURL;
    }
    reader.readAsDataURL(file)
  }

  get form() {
    return this.formData.controls;
  }

  /**
   * Topbar Light-Dark Mode Change
   */
   changeMode(mode: string) {
    this.mode = mode;
    switch (mode) {
      case 'light':
        document.body.setAttribute('data-layout-mode', "light");
        break;
      case 'dark':
        document.body.setAttribute('data-layout-mode', "dark");
        break;
      default:
        document.body.setAttribute('data-layout-mode', "light");
        break;
    }
  }

  /***
   * ========== Group Msg ============
   */
    /**
   * Show user chat
   */
  // tslint:disable-next-line: typedef
  showGroupChat(event:any,id:any) {

    if (this.subscribleMessage) {
      this.subscribleMessage.unsubscribe()
    }
    var removeClass = document.querySelectorAll('.chat-list li');
    removeClass.forEach((element: any) => {
        element.classList.remove('active');
    });
    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    event.target.closest('li').classList.add('active');
    
    this.subscribleMessage = this.events.getObservable().subscribe((data) => {
      console.log("newmess")
      if (data.data.group !== this.dataService.group.id) return
      if (data.type === 'message') {
        const message = data.data
        message.createdAt = util.dateTime(message.createdAt)
        message.isYourMessage = true
        const index = this.members.findIndex((mem:any) => {
          return mem.id === message.createdBy
        })
        if (index !== -1) {
          message.from = this.members[index]
        }

        if (!this.message.length) {
          message.showUserData = true
        } else {
          if (message.createdBy !== this.message[this.message.length - 1].createdBy) {
            message.showUserData = true
          }
        }
        this.message.push(message)
      } else if (data.type === 'delete-message') {
        const index = this.message.findIndex((item: any) => {
          return item.id === data.data
        })
        if (index != -1) {
          this.message.splice(index, 1)
        }
      }
    })
    
    var data = this.groups.filter((group:any) => {
      return group.id === id;
    });
    this.userName = data[0].name
    this.userProfile = ''
    this.message = data[0].messages


  }

  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(content: any) {
    this.closeCall()
    this.audio.pause();
    this.audio.loop = true;
    this.audio.play();
    // this.callSound = new Howl({ 
    //   src: ['assets/sound/call.mp3'],
    //   loop: true,
    //   onload: () => {
    //     this.callSound.play();
    //   }
    // });
    this.modalService.open(content, { centered: true }).dismissed.subscribe(() => {
      this.closeCall()
    }).unsubscribe()
  }

  // Group Search
  GroupSearch(){
    var input:any, filter:any, ul:any, li:any, a:any | undefined, i:any, txtValue:any;
    input = document.getElementById("searchGroup") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".group-list");
    ul.forEach((item:any)=>{
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
      }
    })
  }

  



  _handleSocket() {
    console.log('handle')
    if (this.isInitSocket) return
    this.isInitSocket = true
    socket.subscribe(`socket-event`, (doc:any) => { 
      this._handleChatList(doc)
      this._handleGroupMessage(doc)
      this._handleNewGroup(doc)
      this._handleGroupUpdate(doc)
      this._handleDeleteMessage(doc)
      this._handleGroupCall(doc)
    })

    socket.subscribe(`post-ion-message`, (doc:any) => {
      if (doc.type === 'post') {
        if (doc.action === 'create') {
          this._handleNewPost(doc.data)
        } else if (doc.action === 'reaction') {
          this._handleReaction(doc.data)
        } else if (doc.action === 'comment-created') {
          this._handleNewComment(doc.data)
        } else if (doc.action === 'deleted') {
          this._handledDeletePost(doc.data)
        }
      } else if (doc.type === 'live') {
        if (doc.action === 'online') {
          this.dataService.liveStreamCount = doc.data
        }
      }
    })
  }

  async _handleGroupUpdate(doc:any) {
    if (doc.type === 'updategroup') {
      if (this.dataService.group.id) {
        const res = await axios.get(`${config.api.detailGroup}${this.dataService.group.id}`)
        this.dataService.group = res.data.group
        this.members = res.data.members
        console.log("kjhjkh", this.members)
      }
    }
  }

  _handleNewGroup(doc:any) {
    if (doc.type === 'group') {
      if (!this.dataService.groups.length) {
        let noMessElement = document.getElementById('no-message') as HTMLElement
        noMessElement.classList.add('hidden')
      }
      const group = doc.data
      group.readCount = 0
      if (!group.group) {
        let index = 0
        if (group.users[index].id === this.dataService.currentUser.id) {
          index = 1
        }
        group.member = group.users[index]
      } else {
        group.member = {
          id: 1,
          bgColor: group.color,
          fullName: group.name
        }
        group.readCount = 1
      }
      if (group.callOptions) {
        alert(1)
        this.optionCall = group.callOptions;
        this._handleGroupCall(this.optionCall, true)
      }
      group.lastMessageTime = 'now'
      this.dataService.groups.unshift(group)
    }
  }

  _handleChatList(doc:any) {

    if (doc.type === 'message') {
      const message = doc.data
      const index = this.dataService.groups.findIndex((item:any) => {
        return item.id === message.group
      })
      
      if (index !== -1) {
        this.dataService.groups[index].lastMessage = message.lastMessage
        this.dataService.groups[index].lastMessageTime = util.dateTime(message.createdAt)
        let indexRead = -1;
        if (message.read) {
          indexRead = this.dataService.groups[index].read.findIndex((mem:any) => {
            return mem.memberId === message.read.memberId
          })
        }
        if (indexRead !== -1) {
          this.dataService.groups[index].read[indexRead] = message.read
          this.dataService.groups[index].readCount = message.read.read
        }
      }
      this.getGroups()
    }
  }

  _handleGroupMessage(doc:any) { 
    if (!doc.data.audio && !doc.data.video){
      if (document.hidden || doc.data.group !== this.dataService.group.id) {
        Howler.volume(1);
        console.log("asdad",doc.data.group);
        this.messageSound.play();
      } else {
        Howler.volume(0.1);
        console.log("asdad",doc.data.group);
        this.sendMessageSound.play();
      }
    }
    if (doc.type === 'message' && doc.data.createdBy !== this.dataService.currentUser.id) {
      this.events.publishSomeData({
        type: 'message',
        data: doc.data
      })
    }
  }

  _handleDeleteMessage(doc:any) {
    if (doc.type === 'delete-message') {
      this.events.publishSomeData({
        type: 'delete-message',
        data: doc.data
      })
    }
  }

  _handleGroupCall(doc:any, invite = false) {
    if (!invite && doc.type === 'video-call' && !this.remoteVideos.length) {
      console.log("CALL")
      const group = doc.data.group
      if (!group.group) {
        const index = this.dataService.groups.findIndex((item:any) => {
          return item.id === group.id
        })
        if (index !== -1) {
          group.member = this.dataService.groups[index].member
        }
      }
      this.optionCall = doc.data
      console.log("CALL!")
      // bodyClassList.add('call-comming')
      this.openGroupModal(this.videoCall)
    }
    if (doc.type === 'video-invite' && !this.remoteVideos.length) {
      console.log("CALL")
      const group = doc.data.group
      if (!group.group) {
        const index = this.dataService.groups.findIndex((item:any) => {
          return item.id === group.id
        })
        if (index !== -1) {
          group.member = this.dataService.groups[index].member
        }
      }
      this.optionCall = doc.data.callOptions
      console.log("CALL!")
      // bodyClassList.add('call-comming')
      this.openGroupModal(this.videoCall)
    }
  }

  _handledDeletePost(post:any) {
    const indexPost = this.dataService.posts.findIndex((item:any) => {
      return item.id === post.id
    })
    if (indexPost !== -1) {
      this.dataService.posts.splice(indexPost, 1)
    }
  }

  _handleNewComment(comment:any) {
    const indexPost = this.dataService.posts.findIndex((item:any) => {
      return item.id === comment.postId
    })
    if (indexPost !== -1) {
      this.dataService.posts[indexPost].Comments.push(comment)
    }
  }

  _handleReaction(reaction:any) {
    if (reaction.createdBy === this.dataService.currentUser.id) return

    const indexPost = this.dataService.posts.findIndex((item:any) => {
      return item.id === reaction.postId
    })
    if (indexPost !== -1) {
      const indexReaction = this.dataService.posts[indexPost].Reactions.findIndex((react:any) => {
        return react.id === reaction.id
      })

      if (reaction.message === 'deleted') {
        if (indexReaction !== -1) {
          this.dataService.posts[indexPost].Reactions.splice(indexReaction, 1)
        }
      } else {
        if (indexReaction !== -1) {
          this.dataService.posts[indexPost].Reactions[indexReaction] = reaction
        } else {
          this.dataService.posts[indexPost].Reactions.push(reaction)
        }
      }
      // PostHelper.checkReactionPost(this.dataService.posts[indexPost])
    }
    
  }

  _handleNewPost(post:any) {
    if (!post.Reactions) post.Reactions = []
    if (!post.Comments) post.Comments = []
    this.dataService.posts.unshift(post)
  }


  listGroups() {

    // console.log("ASD:",this.dataService.groups)
    // if (!this.textSearch) return this.dataService.groups
    const textSearch = this.textSearch.toLowerCase()

    return this.dataService.groups.filter((item:any) => {
      let text = ''
      // alert(item.readCount)
      if (item.type == 0 && item.member && item.member.fullName) 
      {
        text = item.member.fullName.toLowerCase()
        return text.indexOf(textSearch) !== -1
      }
    })

  }
  listWorkGroups() {
    // if (!this.textSearch) return this.dataService.groups
    const textSearch = this.textSearch.toLowerCase()
    return this.dataService.groups.filter((item:any) => {
      let text = ''

      if (item.type == 1 && item.member && item.member.fullName) 
      {
        text = item.member.fullName.toLowerCase()
        return text.indexOf(textSearch) !== -1
      }
    })

  }




  async getGroups() {
    let res;
    if (this.chatTab == 0) {
      res = await axios.get(config.api.getGroups)
    } else {
      res = await axios.get(config.api.getArchivedGroups)
    }
    const groups = res.data.map((item:any) => {
      const index = item.read.findIndex((mem:any) => {

      console.log( mem.memberId,  this.dataService.currentUser.id)
        return mem.memberId === this.dataService.currentUser.id
      })

      if (index !== -1) {
        item.readCount = item.read[index].read
        // alert(item.readCount)
      }
      return item
    })

    console.log(this.dataService.groups);
    this.dataService.groups = groups;

    console.log(this.dataService.groups);
    if (!groups.length) {
      // let noMessElement = document.getElementById('no-message') as HTMLElement
      // noMessElement.classList.remove('hidden')
      // this._initImgNoMessage()
    }
  }
  dateConvert(date) {
    
  }

  joinCall() {
    // console.log(this.optionCall);
    this.dataService.group = this.optionCall.group
    // this.callSound.stop()

    this.audio.pause()
    this.modalService.dismissAll();
    this.modalService.open(this.room, { centered: true, fullscreen:true });
    this._handleCall(this.optionCall)
  }

  tabMuteMicro() {
    this.muteMicro = !this.muteMicro
    const index = this.remoteVideos.findIndex((item:any) => {
      return item.type === 'local'
    })
    if (index !== -1) {
      if (this.muteMicro) {
        this.remoteVideos[index].stream.getAudioTracks()[0].enabled = false
      } else {
        this.remoteVideos[index].stream.getAudioTracks()[0].enabled = true
      }
    }
  }

  tabMuteVolume() {
    this.muteVolume = !this.muteVolume
    this.remoteVideos.forEach((item:any) => {
      if (item.type !== 'local') {
        if (this.muteVolume) {
          item.stream.getAudioTracks()[0].enabled = false
        } else {
          item.stream.getAudioTracks()[0].enabled = true
        }
      }
    })
  }

  closeCall() {
    this.modalService.dismissAll()

    this.audio.pause();
    // this.callSound.stop()
    // bodyClassList.remove('video-page')
    // bodyClassList.remove('call-comming')
    this._stopStream()
    if (this.session) {
      this.session.off('streamCreated')
      this.session.off('streamDestroyed')
      setTimeout(() => {
        this.session.disconnect(); 
        this.session = null

    this.modalService.dismissAll();
      }, 1000)
      
    }

    setTimeout(() => {
      this._ngZone.run(() => {
        this.videoSelected = null
        this.remoteVideos = []
        this._refreshVideo()
      })
    }, 500)
    
  }
  _handleCall(optionCall:any) {
    // bodyClassList.remove('call-comming')
    // bodyClassList.add('video-page')

      this._initOpenTokSession(optionCall)
  }


  handleError(error:any) {
    if (error) {
      alert(error.message);
    }
  }


  _refreshVideo() {
  }

  selectVideo(event:any) {
    if (this.videoSelected && this.videoSelected.id === event.id) return
    this.videoSelected = event
    this._refreshVideo()
  }

  _initOpenTokSession(optionCall:any) {
    // alert(window.OT.getSessionInfo(optionCall.group.session))
    this.session = window.OT.initSession(config.openTokKey, optionCall.group.session);
    
    const self = this;
    // Subscribe to a newly created stream
    // Create a publisher
    const publisher = window.OT.initPublisher('publisher', 
    {insertDefaultUI: true, 
      name: self.dataService.currentUser.id}, (error:any) => {
      if (error) return self.handleError


  navigator.mediaDevices.getUserMedia({ video: true, audio: true })
  .then(function (stream) {
      const event = {
        id: self.dataService.currentUser.id,
        stream: stream,
        type: 'local'
      }
      self._ngZone.run(() => {
        self.remoteVideos.push(event)
      })

      setTimeout(() => {
        const localVideo: any = document.getElementById(event.id)
        localVideo.volume = 0
        self._refreshVideo()
      }, 500)
      // self._fixBgIosVideoCall()

    })
      return
    });

    // Connect to the session
    self.session.connect(optionCall.group.token, function(error:any) {
      // If the connection is successful, publish to the sessionзгидшы
      // alert(111)
      if (error) {
        // alert(111)
        self.handleError(error);
      } else {
        self.session.publish(publisher, self.handleError);
      }
    })

    self.session.on('streamCreated', function(event:any) {
      const subscriber = self.session.subscribe(event.stream, {insertDefaultUI: false}, (error:any) => {
        if (error) return self.handleError
        // bodyClassList.remove('call-comming')
        console.log('subscriber', subscriber.stream.name)
        const event = {
          id: subscriber.stream.name,
          stream: subscriber._.webRtcStream(),
          type: 'remote'
        }
        self._ngZone.run(() => {
          self.remoteVideos.push(event)
          if (!self.videoSelected) {
        
            self._ngZone.run(() => {
              self.selectVideo(event)
              self._refreshVideo()
            })
          }
        })
        // self._fixBgIosVideoCall()

        setTimeout(() => {
          self._refreshVideo()
          
        }, 500)
        return
      });
    })

    self.session.on("streamDestroyed", function(data:any) {
      const event = data.stream
      console.log('event', event.id)
      const index = self.remoteVideos.findIndex((item:any) => {
        return item.id === event.name
      })
      console.log('index', index)
      if (index !== -1) {
        self._ngZone.run(() => {
          self._stopStreamedVideo(self.remoteVideos[index].stream)
          if (self.videoSelected && self.remoteVideos[index].id === self.videoSelected.id) {
            self._stopStreamedVideo(self.videoSelected.stream)
            self.videoSelected = null
          }
          self.remoteVideos.splice(index, 1)
          // self._fixBgIosVideoCall()
        })
      }
    })
  }

  _stopStreamedVideo(stream:any) {
    const tracks = stream.getTracks();
  
    tracks.forEach(function(track:any) {
      track.stop();
    });
  }

  _stopStream() {
    this.remoteVideos.forEach((item:any) => {
      this._stopStreamedVideo(item.stream)
    })

    if (this.videoSelected) {
      this._stopStreamedVideo(this.videoSelected.stream)
    }
  }

  async readMessage() {
    let indexMember = -1
    // ТУТ!
    const index = this.dataService.groups.findIndex((item:any) => {
      return item.id === this.dataService.group.id
    })
    if (index !== -1) {
      // ТУТ!
      indexMember = this.dataService.groups[index].read.findIndex((mem:any)  => {
        return mem.memberId === this.dataService.currentUser.id
      })
    }
    if (indexMember !== -1 && this.dataService.groups[index].read[indexMember].read) {
      this.dataService.groups[index].read[indexMember].read = 0
      this.dataService.groups[index].readCount = 0
      this.dataService.group.read[indexMember].read = 0
      return await axios.get(`${config.api.readMessage}${this.dataService.group.id}`)
    }
    return
  }

  initVideoCall() {
    const optionCall = {
      audio: true,
      video: true,
      group: this.dataService.group
    }
    
    this._call(optionCall)
  }

  _call(option: Object) {
    
    this.events.publishSomeData({
      type: 'call',
      data: option
    })

    this.modalService.open(this.room, { centered: true, fullscreen:true });
  }
  showGroupInfo() {    
    const groupCanvas = this.offcanvasService.open(GroupPage, { position: 'end' });
    groupCanvas.componentInstance.group = this.dataService.group;
    groupCanvas.componentInstance.currentUser = this.dataService.currentUser;
    groupCanvas.componentInstance.members = this.members;
  }

  showUserInfo(user) {
    console.log("ASDASDASD")
    // this.offcanvasService.dismiss()
    const groupCanvas = this.offcanvasService.open(ProfilePage, { panelClass: 'details-panel', position: 'end' });
    groupCanvas.componentInstance.profile = user;
    groupCanvas.componentInstance.currentUser = this.dataService.currentUser;
  }
  changeChatTab(id) {
    this.chatTab = id; 
    this.getGroups()
  }
  async changeGroupStatus(group, status_id) {

        const res = await axios.post(config.api.changeGroupStatus, {group_id:group, status_id:status_id})
        // this.dataService.group = null;
        document.querySelector('.user-chat').classList.remove('user-chat-show')
        document.querySelector('.chat-welcome-section').classList.remove('d-none');
        document.querySelector('.user-chat').classList.add('d-none');
        this.closeUserChat()
        this.getGroups()
  }
}
