<!-- Start Settings content -->
<div>
  <div class="px-4 pt-4">
    <h4 class="mb-0">{{'chat.tabs.settings.title' | translate}}</h4>
  </div>

  <div class="text-center border-bottom p-4">
    <div class="mb-4 profile-user">
      <div *ngIf="userInfo.firstname"> 
        <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + userInfo.avatarFullUrl + ')'}" *ngIf="userInfo.avatarFullUrl" ></div>
        <div *ngIf="!userInfo.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" [ngStyle]="{ 'background-color': userInfo.bgColor }">{{ userInfo.firstname ? userInfo.firstname[0] : "no-name"}}</div>
      </div> 
       <input id="profile-img-file-input" type="file" class="profile-img-file-input d-none"
        (change)="fileChange($event)">
      <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-pencil-fill"></i>
        </span>
      </label>
    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{senderName | translate}}</h5>
    <div class="dropdown d-inline-block mb-1" ngbDropdown>
      <a class="text-muted dropdown-toggle pb-1 d-block" href="javascript: void(0);" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
        {{'chat.tabs.settings.status.text' | translate}} <i class="mdi mdi-chevron-down"></i>
      </a>

      <div class="dropdown-menu" ngbDropdownMenu>
        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.status.dropdown.available' |
          translate}}</a>
        <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.status.dropdown.busy' |
          translate}}</a>
      </div>
    </div>
  </div>
  <!-- End profile user -->

  <!-- Start User profile description -->
  <perfect-scrollbar class="p-4 user-profile-desc" data-simplebar>
    <ngb-accordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
      <ngb-panel cardClass="card shadow-none border mb-2" id="perfonal-info">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            {{'chat.tabs.settings.info.title' | translate}}
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="user_name_edit"
              (click)="edit_userName()"><i class="ri-edit-fill me-1 align-middle"></i>
              {{'chat.tabs.settings.info.edit' | translate}}</button>
          </div>
          <div>
            <p class="text-muted mb-1">{{'chat.tabs.settings.info.name.text' | translate}}</p>
            <h5 class="font-size-14" id="user_name">{{ dataService.currentUser.firstname }} {{ dataService.currentUser.lastname }}</h5>
            <div id="edit-user-name" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="userInfo.firstname" class="form-control bg-soft-light border-light"
                maxlength="20" />
                <input type="text" [(ngModel)]="userInfo.lastname" class="form-control bg-soft-light border-light"
                maxlength="20" />
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="saveChanges('name')"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  {{'chat.tabs.settings.info.save' | translate}}</button>
              </div>
            </div>
          </div>


          <hr>


          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="user_email_edit"
              (click)="edit_email()"><i class="ri-edit-fill me-1 align-middle"></i>
              {{'chat.tabs.settings.info.edit' | translate}}</button>
          </div>
          <div>
            <p class="text-muted mb-1">{{'chat.tabs.settings.info.email.text' | translate}}</p>
            <h5 class="font-size-14" id="user_email">{{ dataService.currentUser.email }}</h5>
            <div id="edit-user-email" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="userInfo.email" class="form-control bg-soft-light border-light"
                maxlength="20" />
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="saveChanges('email')"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  {{'chat.tabs.settings.info.save' | translate}}</button>
              </div>
            </div>
          </div>

<hr>

          <div class="float-end">
              <button type="button" class="btn btn-light btn-sm shadow-none" id="user_telephone_edit"
                (click)="edit_telephone()"><i class="ri-edit-fill me-1 align-middle"></i>
                {{'chat.tabs.settings.info.edit' | translate}}</button>
            </div>
            <div>
              <p class="text-muted mb-1">{{'chat.tabs.profile.phone' | translate }}</p>
              <h5 class="font-size-14" id="user_telephone">{{ dataService.currentUser.telephone }}</h5>
              <div id="edit-user-telephone" class="visually-hidden d-flex justify-content-between">
                <input type="text" [(ngModel)]="userInfo.telephone" class="form-control bg-soft-light border-light"
                  maxlength="20" />
                <div class="float-right">
                  <button type="submit" id="receiverSave" (click)="saveChanges('telephone')"
                    class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                    {{'chat.tabs.settings.info.save' | translate}}</button>
                </div>
              </div>
            </div>
          
          

<hr>
          
          
          <div class="float-end">
              <button type="button" class="btn btn-light btn-sm shadow-none" id="user_address_edit"
                (click)="edit_address()"><i class="ri-edit-fill me-1 align-middle"></i>
                {{'chat.tabs.settings.info.edit' | translate}}</button>
            </div>
            <div>
              <p class="text-muted mb-1">{{'chat.tabs.profile.address' | translate }}</p>
              <h5 class="font-size-14" id="user_address">{{ dataService.currentUser.address }}</h5>
              <div id="edit-user-address" class="visually-hidden d-flex justify-content-between">
                <input type="text" [(ngModel)]="userInfo.address" class="form-control bg-soft-light border-light"
                  maxlength="20" />
                <div class="float-right">
                  <button type="submit" id="receiverSave" (click)="saveChanges('address')"
                    class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                    {{'chat.tabs.settings.info.save' | translate}}</button>
                </div>
              </div>
            </div>
          
          

<hr>
          
          
          <div class="float-end">
              <button type="button" class="btn btn-light btn-sm shadow-none" id="user_password_edit"
                (click)="edit_password()"><i class="ri-edit-fill me-1 align-middle"></i>
                {{'chat.tabs.settings.info.edit' | translate}}</button>
            </div>
            <div>
              <p class="text-muted mb-1">{{'chat.tabs.profile.password' | translate }}</p>
              <h5 class="font-size-14" id="user_password">********</h5>
              <div id="edit-user-password" class="visually-hidden d-flex justify-content-between">
                <input type="password" [(ngModel)]="userInfo.password" class="form-control bg-soft-light border-light"
                  maxlength="20" />
                <div class="float-right">
                  <button type="submit" id="receiverSave" (click)="saveChanges('password')"
                    class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                    {{'chat.tabs.settings.info.save' | translate}}</button>
                </div>
              </div>
            </div>
          
          

<hr>
          
          
          <div class="float-end">
              <button type="button" class="btn btn-light btn-sm shadow-none" id="user_citizenship_edit"
                (click)="edit_citizenship()"><i class="ri-edit-fill me-1 align-middle"></i>
                {{'chat.tabs.settings.info.edit' | translate}}</button>
            </div>
            <div>
              <p class="text-muted mb-1">{{'chat.tabs.profile.citizenship' | translate }}</p>
              <h5 class="font-size-14" id="user_citizenship">{{ dataService.currentUser.citizenship }}</h5>
              <div id="edit-user-citizenship" class="visually-hidden d-flex justify-content-between">
                <input type="text" [(ngModel)]="userInfo.citizenship" class="form-control bg-soft-light border-light"
                  maxlength="20" />
                <div class="float-right">
                  <button type="submit" id="receiverSave" (click)="saveChanges('citizenship')"
                    class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                    {{'chat.tabs.settings.info.save' | translate}}</button>
                </div>
              </div>
            </div>
          

        </ng-template>
      </ngb-panel>
      <!--<ngb-panel cardClass="card shadow-none border mb-2" id="privacy">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            {{'chat.tabs.settings.privacy.title' | translate}}
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="py-3">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.photo' | translate}}</h5>

              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                  {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                    translate}}</a>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.selected' |
                    translate}}</a>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                    translate}}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="py-3 border-top">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.seen' | translate}}</h5>

              </div>
              <div class="ms-2 me-0">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="privacy-lastseenSwitch" checked="">
                  <label class="form-check-label" for="privacy-lastseenSwitch"></label>
                </div>
              </div>
            </div>
          </div>

          <div class="py-3 border-top">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.status' | translate}}</h5>
              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                  {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                    translate}}</a>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.selected' |
                    translate}}</a>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                    translate}}</a>
                </div>
              </div>
            </div>
          </div>

          <div class="py-3 border-top">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.receipt' | translate}}</h5>

              </div>
              <div class="ms-2 me-0">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="privacy-readreceiptSwitch" checked="">
                  <label class="form-check-label" for="privacy-readreceiptSwitch"></label>
                </div>
              </div>
            </div>
          </div>

          <div class="py-3 border-top">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.groups' | translate}}</h5>

              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm" ngbDropdownToggle type="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ 'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                    translate}}</a>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.selected' |
                    translate}}</a>
                  <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                    translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel cardClass="card shadow-none border mb-2" id="security">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            {{'chat.tabs.settings.security.title' | translate}}
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.security.text' | translate}}</h5>
              </div>
              <div class="ms-2 me-0">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="security-notificationswitch">
                  <label class="form-check-label" for="security-notificationswitch"></label>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel cardClass="card shadow-none border mb-2" id="help">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            {{'chat.tabs.settings.help.title' | translate}}
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div>
            <div class="py-3">
              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);"
                  class="text-body d-block">{{'chat.tabs.settings.help.faqs' | translate}}</a>
              </h5>
            </div>
            <div class="py-3 border-top">
              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);"
                  class="text-body d-block">{{'chat.tabs.settings.help.contact' | translate}}</a></h5>
            </div>
            <div class="py-3 border-top">
              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);"
                  class="text-body d-block">{{'chat.tabs.settings.help.policy' | translate}}</a>
              </h5>
            </div>
          </div>
        </ng-template>
      </ngb-panel>-->
    </ngb-accordion>
    <!-- end profile-setting-accordion -->
  </perfect-scrollbar>
  <!-- End User profile description -->
</div>
<!-- Start Settings content -->