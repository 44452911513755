<!-- Start Settings content -->

<div class="layout-wrapper d-lg-flex">
  <div class="chat-leftsidebar me-lg-1">
<div>

  <div class="text-center border-bottom p-4">

  <div class="px-4 pt-4">
    <h4 class="mb-0">{{'chat.tabs.settings.title' | translate}}</h4>
    </div>
    <div class="mb-4 profile-user">
      <p>{{'chat.tabs.profile.qr_invite' | translate }}</p>
      <qrcode [qrdata]="dataService.currentUser.company.invite_token" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> 
      
      <p>{{'chat.tabs.profile.invite_link_text' | translate }}</p>
      <div id="edit-company_name" class="d-flex justify-content-between">
        <input  [value]="'https://interactivetelecare.com/companyInvite.php?token=' + dataService.currentUser.company.invite_token" type="text" #userinput readonly class="form-control bg-soft-light border-light"
          />
        <div class="float-right">
          <button (click)="copyInputMessage(userinput)"
            class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
            {{text_copy}}</button>
        </div>
      </div>
    </div>

  </div>
  <!-- End profile user -->

  <!-- Start User profile description -->
  <perfect-scrollbar style="height:calc(100vh - 505px)" class="p-4 user-profile-desc" data-simplebar>
    <ngb-accordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
      <ngb-panel cardClass="card shadow-none border mb-2" id="perfonal-info">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            {{'chat.tabs.profile.company_info' | translate }}
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="company_name_edit"
              (click)="edit_name()"><i class="ri-edit-fill me-1 align-middle"></i>
              {{'chat.tabs.settings.info.edit' | translate}}</button>
          </div>
          <div>
            <p class="text-muted mb-1">{{'chat.tabs.settings.info.name.text' | translate}}</p>
            <h5 class="font-size-14" id="company_name">{{ dataService.currentUser.company.name }}</h5>
            <div id="edit-company_name" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="userInfo.company.name" class="form-control bg-soft-light border-light"
                />
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="saveChanges('name')"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  {{'chat.tabs.profile.save' | translate }}</button>
              </div>
            </div>
          </div>


          <hr>
          
          
          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="company_website_edit"
              (click)="edit_website()"><i class="ri-edit-fill me-1 align-middle"></i>
              {{'chat.tabs.settings.info.edit' | translate}}</button>
          </div>
          <div>
            <p class="text-muted mb-1"> {{'chat.tabs.profile.website' | translate }}</p>
            <h5 class="font-size-14" id="company_website">{{ dataService.currentUser.company.config.website }}</h5>
            <div id="edit-company_website" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="userInfo.company.config.website" class="form-control bg-soft-light border-light"
                />
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="saveChanges('website')"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  {{'chat.tabs.profile.save' | translate }}</button>
              </div>
            </div>
          </div>


          


          <hr>
          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="company_address_edit"
              (click)="edit_address()"><i class="ri-edit-fill me-1 align-middle"></i>
              {{'chat.tabs.settings.info.edit' | translate}}</button>
          </div>
          <div>
            <p class="text-muted mb-1"> {{'chat.tabs.profile.address' | translate }}</p>
            <h5 class="font-size-14" id="company_address">{{ dataService.currentUser.company.config.address }}</h5>
            <div id="edit-company_address" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="userInfo.company.config.address" class="form-control bg-soft-light border-light"
                />
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="saveChanges('address')"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  {{'chat.tabs.profile.save' | translate }}</button>
              </div>
            </div>
          </div>


          


          <hr>
          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="company_telephone_edit"
              (click)="edit_telephone()"><i class="ri-edit-fill me-1 align-middle"></i>
              {{'chat.tabs.settings.info.edit' | translate}}</button>
          </div>
          <div>
            <p class="text-muted mb-1"> {{'chat.tabs.profile.phone' | translate }}</p>
            <h5 class="font-size-14" id="company_telephone">{{ dataService.currentUser.company.config.telephone }}</h5>
            <div id="edit-company_telephone" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="userInfo.company.config.telephone" class="form-control bg-soft-light border-light"
                />
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="saveChanges('telephone')"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  {{'chat.tabs.profile.save' | translate }}</button>
              </div>
            </div>
          </div>


          


          <hr>

          

        </ng-template>
      </ngb-panel>
      
    </ngb-accordion>
    <!-- end profile-setting-accordion -->
  </perfect-scrollbar>
  <!-- End User profile description -->
</div>
<!-- Start Settings content -->

</div>
<div class="company-setting-section">
  
  <div class="px-4 pt-4 full-width">
    <h4 class="mb-0"> {{'chat.tabs.profile.team' | translate }}</h4>


  <div class="pull-right">
    <button (click)="addUserModal()" class="btn btn-primary"><i class="ri-user-add-line"></i>  {{'chat.tabs.profile.add_user' | translate }}</button>
  </div>
    </div>
    
        <ul class="list-unstyled contact-list">
          <li class="coworker" *ngFor="let item of contacts; let i = index">
            <div class="row">
              <div class="col-md-4">
            <div class="d-flex align-items-center">
              <div class="chat-user-img online align-self-center me-3 ms-0">
                
                <img *ngIf="item.avatarFullUrl" src="{{item.avatarFullUrl}}" class="rounded-circle avatar-xs"
                  alt="">
                <div class="avatar-xs" *ngIf="!item.avatarFullUrl ">
                  <span class="avatar-title rounded-circle"  [ngStyle]="{ 'background': item.bgColor, 'color': '#fff' }">
                    {{( item.firstname ? item.firstname : "No name" ).charAt(0)}}
                  </span>
                </div>
              </div>

              <div class="flex-grow-1" >
                <h5 class="font-size-14 m-0">{{ gender[item.sex] }} {{item.firstname }} {{item.lastname }}</h5>
                <p class="text-muted text-truncate mb-1" *ngIf="item.online"><i
                  class="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>
                 
                Online</p>
            
                <p class="text-muted text-truncate mb-1" *ngIf="!item.online"><i
                  class="ri-record-circle-fill font-size-10 text-danger me-1 d-inline-block"></i>
                 
                Offline
              </p>
              </div>

            </div>
            <p *ngIf="!item.online" style="margin: 0;font-size: 10px;font-weight: bold;color: #999;">
              Last visited: {{item.lastonline  | date: 'dd/MM/yyyy  hh:mm a'}}
            </p>
            </div>
            <div class="col-md-4">
              <div>
              <b>Telephone:</b> {{item.telephone}}<br>
              <b>Email:</b> {{item.email}}
            </div>
            </div>
            <div class="col-md-3">
             <div><b>Role: </b> <span *ngIf="item.updated" class="updated"> {{'chat.tabs.profile.updated' | translate }}</span></div> 
              <select class="form-select" (change)="updateRole(item, $event.target.value, i)" aria-label="Default select example">
                <option value="1" [selected]="item.usergroup == 1">SuperAdmin</option>
                <option value="2" [selected]="item.usergroup == 2">Operator</option>
                <option value="3" [selected]="item.usergroup == 3">Doctor</option>
              </select>
              </div>
              <div class="col-md-1">
                <div>
              <button data-toggle="tooltip" data-placement="top" ngbTooltip="Delete" (click)="alert('This feature is currently being updated. Please try again later.')" class="btn btn-danger btn-block waves-effect waves-light">
                <i class="ri-delete-bin-line"></i>
              </button>
            </div>
            </div>
          </div>
          </li>
        </ul>
</div>
</div>